.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-inline: 2rem;

  @media screen and (max-width: 769px) {
    padding-inline: 1rem;
  }

  @media screen and (min-width: 769px) {
    min-width: 1280px;
  }
}

.tour-highlight{
  box-shadow: none;
  z-index: 9999;

}

.flex-1 {
  flex: 1;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transition {
  transition: all 150ms ease-in-out;
}

.capitalize {
  text-transform: capitalize;
}

.hover-underline:hover {
  text-decoration: underline;
}
.cursor-pointer {
  cursor: pointer;
}
