$shadows: (
  'modal': 5px 10px 30px rgba(0, 0, 0, 0.3),
  'yellow-btn': 4px 4px 6px 0px #c8c8c880,
  'yellow-btn1': 4px 4px 6px rgba(200, 200, 200, 0.5),
  'indigo-btn': 0px 3px 5px 0px #000000b2,
  'violet-btn': 0px 1.5px 3px rgba(0, 0, 0, 0.3),
  'big-btn': 8px 8px 30px #000000,
  'form': -1.84047px 2.45396px 12.2698px #ccc8c8,
  'card': 0px 3px 12px #ccc8c8,
  'card-small': 0px 2px 4px 0px #ccc8c8b2,
  'card-black': 8px 8px 8px 2px #00000066,
  'icon': 0px 2px 3px rgba(0, 0, 0, 0.25),
  'mobile-icon': 0px 2px 4px 0px #ccc8c8,
  'flag': 0px 2px 3px rgba(0, 0, 0, 0.25),
  'flag-lift': 0px 5px 5px #eddaff,
  'dropdown-art-assets': 2px 4px 10px 0px rgba(0, 0, 0, 0.15),
  'about-page-fonts': 0px 2px 3.5px #00000026,
  'card-innocent': 0px 4px 30px rgba(0, 0, 0, 0.08),
  'side-popup': 0px 8px 30px rgba(147, 56, 221, 0.1),
  'mobile-text-box': 0px 0px 4px rgba(0, 0, 0, 0.1),
  'mobile-box': 0px 1px 4px rgba(204, 200, 200, 0.7),
  'big-images': 0px 4px 30px 0px #00000014,
  'mobile-main-page-objects': 0px 0px 10px 0px rgba(0, 0, 0, 0.1),
);

@each $name, $shadow in $shadows {
  .shadow-#{$name} {
    box-shadow: #{$shadow};
  }

  .font-shadow-#{$name} {
    text-shadow: #{$shadow};
  }

  :root {
    --shadow-#{$name}: #{$shadow};
  }
}
