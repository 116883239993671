@keyframes fadeIn {
  from {
    transition: scale(0.9);
    opacity: 0;
  }
  to {
    transition: scale(1);
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    transition: scale(1);
    opacity: 1;
  }
  to {
    transition: scale(0.9);
    opacity: 0;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
}

@keyframes scaleInUp {
  from {
    transform: scale(0.5) translateY(20px);
  }
  to {
    transform: scale(1) translateY(0);
  }
}

@keyframes scaleInDown {
  from {
    transform: scale(0.5) translateY(-20px);
  }
  to {
    transform: scale(1) translateY(0);
  }
}

@keyframes scaleInLeft {
  from {
    transform: scale(0.5) translateX(-20px);
  }
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes scaleInRight {
  from {
    transform: scale(0.5) translateX(20px);
  }
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes skeleton-animation {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
.skeleton-item {
  -webkit-animation: skeleton-animation 1.8s infinite ease-in-out;
  animation: skeleton-animation 1.8s infinite ease-in-out;
}
