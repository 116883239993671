@use '../variables/typography' as *;

@each $family, $value in $font-family {
  .font-#{$family} {
    font-family: $value;
  }
}

@each $size, $value in $font-sizes {
  .font-size-#{$size} {
    font-size: $value;
  }
}

@each $spacing, $value in $letter-spacings {
  .letter-spacing-#{$spacing} {
    letter-spacing: $value;
  }
}

@each $line-height, $value in $line-height {
  .line-height-#{$line-height} {
    line-height: $value;
  }
}

@each $weight, $value in $font-weights {
  .font-weight-#{$weight} {
    font-weight: $value;
  }
}

:root {
  @each $family, $value in $font-family {
    --font-family-#{$family}: #{$value};
  }
  @each $size, $value in $font-sizes {
    --font-size-#{$size}: #{$value};
  }
  @each $spacing, $value in $letter-spacings {
    --letter-spacing-#{$spacing}: #{$value};
  }
  @each $line-height, $value in $line-height {
    --line-height-#{$line-height}: #{$value};
  }
  @each $weight, $value in $font-weights {
    --font-weight-#{$weight}: #{$value};
  }
}

.ebh {
  @extend .font-weight-800;
  @extend .font-kumbh-sans;
  color: var(--color-indigo-500);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

.ebh1 {
  @extend .ebh;
  @extend .font-size-60;
  @extend .line-height-1\.3;
  @extend .letter-spacing-0\.5;
}
.ebh2 {
  @extend .ebh;
  @extend .font-size-48;
  @extend .line-height-1\.3;
  @extend .letter-spacing-0\.5;
}

.bh {
  @extend .font-weight-700;
  @extend .font-kumbh-sans;
  color: var(--color-indigo-500);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

.bh1 {
  @extend .bh;
  @extend .font-size-32;
  @extend .letter-spacing-0;
  @extend .line-height-1\.2;
}

.bh2 {
  @extend .bh;
  @extend .font-size-24;
  @extend .letter-spacing-0;
  @extend .line-height-1\.2;
}

.bh2\.5 {
  @extend .bh;
  @extend .font-size-22;
  @extend .letter-spacing-0;
  @extend .line-height-1\.5;
}

.bh3 {
  @extend .bh;
  @extend .font-size-20;
  @extend .letter-spacing-0;
  @extend .line-height-1\.2;
}

.bh4 {
  @extend .bh;
  @extend .font-size-18;
  @extend .letter-spacing-0;
  @extend .line-height-1\.5;
}

.bh4\.5 {
  @extend .bh;
  @extend .font-size-17;
  @extend .letter-spacing-0;
  @extend .line-height-1\.5;
}

.bh5 {
  @extend .bh;
  @extend .font-size-16;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.5;
}

.bh5\.5 {
  @extend .bh;
  @extend .font-size-15;
  @extend .letter-spacing-0;
  @extend .line-height-1\.5;
}

.bh6 {
  @extend .bh;
  @extend .font-size-14;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.bh6\.5 {
  @extend .bh;
  @extend .font-size-13;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.2;
}

.bh7 {
  @extend .bh;
  @extend .font-size-12;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.bh8 {
  @extend .bh;
  @extend .font-size-10;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}
.bh9 {
  @extend .bh;
  @extend .font-size-8;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.sbh {
  @extend .font-weight-600;
  @extend .font-kumbh-sans;
  color: var(--color-indigo-500);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

.sbh1 {
  @extend .sbh;
  @extend .font-size-32;
  @extend .letter-spacing-0;
  @extend .line-height-1\.5;
}

.sbh2 {
  @extend .sbh;
  @extend .font-size-24;
  @extend .letter-spacing-0;
  @extend .line-height-1\.5;
}

.sbh3 {
  @extend .sbh;
  @extend .font-size-20;
  @extend .letter-spacing-0;
  @extend .line-height-1\.5;
}

.sbh4 {
  @extend .sbh;
  @extend .font-size-18;
  @extend .letter-spacing-0;
  @extend .line-height-1\.5;
}

.sbh5 {
  @extend .sbh;
  @extend .font-size-16;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.5;
}

.sbh5\.5 {
  @extend .sbh;
  @extend .font-size-15;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.5;
}

.sbh6 {
  @extend .sbh;
  @extend .font-size-14;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.5;
}

.sbh6\.5 {
  @extend .sbh;
  @extend .font-size-13;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.5;
}

.sbh7 {
  @extend .sbh;
  @extend .font-size-12;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.5;
}
.sbh8 {
  @extend .sbh;
  @extend .font-size-10;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.5;
}
.sbh9 {
  @extend .sbh;
  @extend .font-size-8;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.5;
}

.psbh {
  @extend .font-weight-600;
  @extend .font-poppins;
  color: var(--color-indigo-500);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

.psbh20 {
  @extend .psbh;
  @extend .font-size-20;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.7;
}

.psbh18 {
  @extend .psbh;
  @extend .font-size-18;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.6;
}

.psbh16 {
  @extend .psbh;
  @extend .font-size-16;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.6;
}

.h {
  @extend .font-weight-500;
  @extend .font-kumbh-sans;
  color: var(--color-indigo-500);
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

.h1,
h1 {
  @extend .h;
  @extend .font-size-48;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.3;
}

.h2,
h2 {
  @extend .h;
  @extend .font-size-32;
  @extend .letter-spacing-0;
  @extend .line-height-1\.2;
}

.h3,
h3 {
  @extend .h;
  @extend .font-size-24;
  @extend .letter-spacing-0;
  @extend .line-height-1\.2;
}

.h4,
h4 {
  @extend .h;
  @extend .font-size-20;
  @extend .letter-spacing-0;
  @extend .line-height-1\.4;
}

.h4\.5{
  @extend .h;
  @extend .font-size-19;
  @extend .letter-spacing-0;
  @extend .line-height-1\.4;
}

.h5,
h5 {
  @extend .h;
  @extend .font-size-18;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.4;
}
.h5-lh160 {
  @extend .h;
  @extend .font-size-18;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.6;
}

.h5\.5 {
  @extend .h;
  @extend .font-size-17;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.7;
}

.h6,
h6 {
  @extend .h;
  @extend .font-size-16;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.2;
}

.h6-lh160 {
  @extend .h;
  @extend .font-size-16;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.6;
}

.h6\.5 {
  @extend .h;
  @extend .font-size-15;
  @extend .letter-spacing-0\.15;
  @extend .line-height-1\.5;
}

.h7 {
  @extend .h;
  @extend .font-size-14;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.h7\.5 {
  @extend .h;
  @extend .font-size-13;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.h8 {
  @extend .h;
  @extend .font-size-12;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.h9 {
  @extend .h;
  @extend .font-size-10;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}
.h10 {
  @extend .h;
  @extend .font-size-8;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.mh12 {
  @extend .font-lato;
  @extend .font-size-12;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.mh13 {
  @extend .font-lato;
  @extend .font-size-13;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
  @extend .font-weight-400;
}

.mh14 {
  @extend .font-lato;
  @extend .font-size-14;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
  @extend .font-weight-400;
}

.mh16 {
  @extend .font-lato;
  @extend .font-size-16;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
  @extend .font-weight-400;
}

.btn {
  @extend .font-weight-700;
  @extend .font-space-grotesk;
  color: var(--color-indigo-500);
}

.btn1 {
  @extend .btn;
  @extend .font-size-24;
  @extend .letter-spacing-0;
  @extend .line-height-1\.2;
}

.btn2 {
  @extend .btn;
  @extend .font-size-20;
  @extend .letter-spacing-0;
  @extend .line-height-1\.2;
}

.btn3 {
  @extend .btn;
  @extend .font-size-18;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.btn4,
button {
  @extend .btn;
  @extend .font-size-16;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.btn5 {
  @extend .btn;
  @extend .font-size-14;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.btn6 {
  @extend .btn;
  @extend .font-size-12;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.text {
  @extend .font-weight-400;
  @extend .font-kumbh-sans;
  @extend .line-height-1\.5;
  @extend .letter-spacing-0\.5;
}

.text-lg {
  @extend .text;
  @extend .font-size-18;
}

.text-md,
p {
  @extend .text;
  @extend .font-size-16;
}

.text-sm {
  @extend .text;
  @extend .font-size-14;
}

.text-xs {
  @extend .text;
  @extend .font-size-12;
}

.text-m {
  @extend .font-weight-400;
  @extend .font-margarine;
  color: var(--color-indigo-500);
}

.text-m1 {
  @extend .text-m;
  @extend .font-size-24;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.text-m2 {
  @extend .text-m;
  @extend .font-size-20;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

.text-about-subtitle {
  @extend .font-weight-500;
  @extend .font-poppins;
  @extend .font-size-20;
  @extend .letter-spacing-0\.4;
  @extend .line-height-1\.2;
}

.text-about-title {
  @extend .font-weight-500;
  @extend .font-kumbh-sans;
  @extend .font-size-32;
  @extend .letter-spacing-0\.5;
  @extend .line-height-1\.5;
}

@mixin ellipsis($line-cnt, $line-height) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-cnt;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: $line-height;
  max-height: $line-height * $line-cnt * 1em !important;
}

$lines: 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20;

@each $line in $lines {
  @each $line-height, $value in $line-height {
    .ellipsis-#{$line}-#{$line-height} {
      @include ellipsis($line, $value);
    }
  }
}
